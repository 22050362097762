











import { Vue, Component } from 'vue-property-decorator';
import SectionContainer from "@/components/section/SectionContainer.vue";
import HeadingCard from "@/components/section/HeadingCard.vue";

@Component({
  components: {
    SectionContainer,
    HeadingCard,
  },
})
export default class NotFound extends Vue {
 
}
