



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { proxies } from '@/store';

@Component
export default class SectionContainer extends Vue {
  @Prop(String) private readonly title!: string;
  @Prop(Boolean) private readonly small!: boolean;
  private history = proxies.History;
  private guide = proxies.Guide;
  private compose = proxies.Compose;

  private get colSettings() {
    if (this.small) {
      return { sm: 12, md: 8, lg: 6, xl: 4 }
    }

    const leftDrawerOpen = !!this.history.open;
    const rightDrawerOpen = !!this.guide.open || !!this.compose.open;

    if (leftDrawerOpen && rightDrawerOpen) {
      return { sm: 12, md: 12, lg: 12, xl: 10 }
    }

    if (leftDrawerOpen != rightDrawerOpen) {
      return { sm: 12, md: 12, lg: 10, xl: 6 }
    }

    return { sm: 12, md: 10, lg: 8, xl: 6 }
  }
};
